import api, { setApiUserToken } from 'config/api'
import { STORAGE_KEYS, getStorageItem } from 'store/reducers/storage'

export interface ICurrency {
  loan_deposit_priority: number
  loan_receive_priority: number
  earn_priority: number
  exchange_priority: number
  listed_at: string
  code: string
  network: string
  name: string
  is_extra_id_exists: boolean
  decimal_places: number
  smart_contract: string
  is_stable: boolean
  is_allowed_for_wallet: boolean
  is_loan_deposit_enabled: boolean
  is_loan_receive_enabled: boolean
  is_earn_enabled: boolean
  loan_deposit_default_amount: string
  loan_receive_default_amount: string
  earn_default_amount: string
  logo_url: string
  display_code: string
  display_network: string
  loan_deposit_min_amount: string
  loan_deposit_max_amount: string
  loan_receive_min_amount: string
  loan_receive_max_amount: string
  earn_min_amount: string
  earn_max_amount: string
  loan_deposit_rate_percent: string
  loan_receive_rate_percent: string
  earn_rate_percent: string
  loan_deposit_additional_fee: string
  loan_receive_additional_fee: string
  template_explorer_hash: string
  regexp_address: string
  regexp_extra_id: string
  color_network: string
  placeholder_address: string
  placeholder_extra_id: string
  is_extra_id_mandatory: boolean
  ltv_options: string[]
  exchange: string
}

export interface ICurrencyWithBalance extends ICurrency {
  balance?: number
}

export interface ICurrencyWithBalanceAndRate extends ICurrency {
  balance?: number
  rate?: string
}

export const _fetchCurrencies = async (params?: {
  network?: string
  code?: string
  is_enabled?: string
}): Promise<ICurrency[]> => {
  try {
    const { data } = await api.get('/v2/currencies', {
      params: params,
    })
    return data.response
  } catch (ignore) {
    return []
  }
}
export const _fetchCurrenciesWithBalance = async (): Promise<ICurrency[]> => {
  try {
    const token = getStorageItem(STORAGE_KEYS.AUTH_TOKEN)
    await setApiUserToken(token)
    const { data } = await api.get('/v2/wallet/balance/currency')
    return data.response
  } catch (ignore) {
    return null
  }
}
