import { PropsWithChildren, FC, useCallback, useState } from 'react'
import { createPortal } from 'react-dom'
import cn from 'classnames'

import { PopupWrapper } from 'components/app/popup'
import IconClose from 'images/icon-close-blue.svg'
import useWindowWidth from 'hooks/use-window-width'

import styles from './styles.module.scss'

interface Props {
  heading: string
  onClose: () => void
  subtitle?: string
  className?: string
  parrentIsClosing?: boolean
  isDark?: boolean
  isWithoutClose?: boolean
}

const Popup: FC<PropsWithChildren<Props>> = ({
  onClose,
  heading,
  subtitle,
  className,
  children,
  parrentIsClosing,
  isDark,
  isWithoutClose,
}) => {
  const [isClosing, setIsClosing] = useState(false)
  const width = useWindowWidth()

  const onClosePopup = useCallback(() => {
    if (width < 766) {
      setIsClosing(true)
      setTimeout(() => onClose(), 300)
      return
    }

    onClose()
  }, [width])

  return createPortal(
    <PopupWrapper
      onClose={onClosePopup}
      classNames={[
        styles.container,
        (isClosing || parrentIsClosing) && styles.closedContainer,
        isDark && styles.dark,
        className,
      ]}
    >
      <div className={styles.header}>
        <h2 className={styles.title}>{heading}</h2>

        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>

      <div className={cn(styles.main, className)}>{children}</div>

      {!isWithoutClose && (
        <button className={styles.btn} type="button" onClick={onClosePopup}>
          <img src={IconClose} alt="close" />
        </button>
      )}
    </PopupWrapper>,
    document?.body,
  )
}

export default Popup
